import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { media } from "../../utils/media";
import Footer from "../../components/Footer";
import ReactDOM from "react-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaShareAlt, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import toast from "react-hot-toast";
import axios from "axios";
import { CiPaperplane } from "react-icons/ci";
import TransformedNivoBarGraph from "../../pages/ChatHistory/TransformedBarGraph";
import TransformedNivoLineGraph from "../../pages/ChatHistory/TransformedLineGraph"
import TransformedTable from "../../pages/ChatHistory/TransformedTable";

const Dropdown = ({ category, setCategory, categories }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const dropdownMenuRef = useRef(null);
  useEffect(() => {
    if (showDropdown && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [showDropdown]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton onClick={() => setShowDropdown((prev) => !prev)}>
      {category} <span><b>&or;</b></span>
      </DropdownButton>
      {showDropdown &&
        ReactDOM.createPortal(
          <DropdownMenu ref={dropdownMenuRef} style={{ top: position.top, left: position.left }}>
            {categories.map((cat) => (
              <DropdownItem
                key={cat}
                onClick={() => {
                  setCategory(cat);
                  setShowDropdown(false);
                }}
              >
                {cat}
              </DropdownItem>
            ))}
          </DropdownMenu>,
          document.body
        )}
    </DropdownWrapper>
  );
};
const LoaderProgress = () => (
  <WaveLoaderContainer>
    <WaveLoader>
      <WaveBar delay="0s" />
      <WaveBar delay="0.2s" />
      <WaveBar delay="0.4s" />
      <WaveBar delay="0.6s" />
      <WaveBar delay="0.8s" />
    </WaveLoader>
    <LoaderText>Intializing Analysis</LoaderText>
  </WaveLoaderContainer>
);

const SearchTimeIndicator = ({ searchTime }) => (
  <SearchTimeContainer>
    <SearchTimeText>Search completed in {searchTime} seconds</SearchTimeText>
  </SearchTimeContainer>
);

const SearchBarComponent = () => {
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("Platforms");
  const [loading, setLoading] = useState(false);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searching, setSearching] = useState(false);
  const [messages, setMessages] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedback, setFeedback] = useState(""); 
  const [hoveredQuestion, setHoveredQuestion] = useState(null);
  const [openFeedbackModalId, setOpenFeedbackModalId] = useState(null);
  const modalRef = useRef(null);
  const categories = ["Platforms", "Brands"];
  const marginRight = 50;
  const marginLeft = 50;
  const chatEndRef = useRef(null);
  const [selectedFeedback, setSelectedFeedback] = useState({});
  const platformQuestions = [
    "What is the growth of Quick Commerce in last 4 quarters?",
    "How does the growth of Food Delivery fares with Ride Hailing Market?",
    "What is the contribution of Metro cities in GMV across key online retail sectors?",
    "Which is the GMV mix of key categories in Online Retail?"
  ];

  const brandQuestions = [
    "Which are the top 20 new age brands in India?",
    "What is the share of new age brands in fashion market in India?",
    "What is the share of branded market across key categories in retail?",
    "How has the online penetration grown in overall retail over last 5 years?"
  ];
  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };
  const formatTitle = (title, chartData) => {
    if (!title) return title;
    if (
      (title.includes("USD=") && (title.includes("(USD Mn)") || title.includes("(INR Cr)"))) ||
      (title.includes("(INR)") && title.includes("(INR Cr)"))
    ) {
      return title;
    }
    let usdRate = "";
    const usdMatch = title.match(/USD=([\d.]+)\s*INR/);
    if (usdMatch) {
      usdRate = `USD=${usdMatch[1]} INR`;
    }
    let unit = "";
    if (chartData?.Values) {
      const maxValue = Math.max(...chartData.Values.flatMap((v) => v.Data));

      if (usdMatch && maxValue >= 1_000_000) {
        unit = "(USD Mn)";
      } else if (title.includes("INR") && maxValue >= 10_000_000) {
        unit = "(INR Cr)";
      }
    }

    let newTitle = title;
    newTitle = newTitle.replace(/\(USD=[\d.]+ INR\)/, "").trim();
    newTitle = newTitle.replace(/\((USD Mn|INR Cr)\)/, "").trim();
    newTitle = newTitle.replace(/\(INR\)/, "").trim();
    if (usdRate && unit) {
      return `${newTitle} (${usdRate}) ${unit}`;
    } else if (usdRate) {
      return `${newTitle} (${usdRate})`;
    } else if (unit) {
      return `${newTitle} ${unit}`;
    }
    return newTitle;
  };


  const wrapperRef = useRef(null);
  const updateWrapperWidth = () => {
    if (wrapperRef.current) {
      if (wrapperRef.current.offsetWidth)
        setWrapperWidth(
          wrapperRef.current.offsetWidth - marginLeft - marginRight
        );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);
  useEffect(() => {
    updateWrapperWidth();
    window.addEventListener("resize", updateWrapperWidth);
    return () => {
      window.removeEventListener("resize", updateWrapperWidth);
    };
  }, [wrapperRef, marginLeft, marginRight]);
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  },[messages.length !== 0])
  const handleSearch = async (customQuery) => {
    const searchQuery = customQuery || query;
    if (!searchQuery.trim()) return;
    setQuery(searchQuery)
    const uniqueId = `chat-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    const startTime = Date.now();
    const dataSource = category;
    const userMessage = {
        id: Date.now(),
        text: searchQuery,
        sender: 'user',
        timestamp: new Date().toLocaleTimeString(),
        unique_id: uniqueId
    };
    setMessages(prev => [...prev, userMessage]);
    setQuery("");
    setSearching(true);
    setTimeout(() => {
      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",  
        });
      }
    }, 100)
    setLoading(true);
    try {
        let url = '';
        let headers = {
            'Content-Type': 'application/json',
        };
        let body = {};
        if (category === 'Platforms') {
            url = `https://api.redseer.apperture.io/v2/chat/web?redseer_api_key=b4161132-e238-4bfa-a819-81bcc059cf74`;
            body = {
                question: searchQuery,
                user_id: '5eb7cf5a86d9755df3a6c593',
            };
        } else if (category === 'Brands') {
            url = `https://agami-search-122967862157.us-central1.run.app/ask_query_v3`;
            headers['x-api-key'] = 'agami_cb06e284-c629-4874-a975-76c47b5ae0c4';
            body = {
                query: searchQuery,
                collection: 'brand_data',
            };
        }

        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        });

        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        const insight = category === 'Brands' 
            ? data?.SearchQueryResponses?.[0]?.Insights?.[0] 
            : data?.Insights?.[0];      
        let aiMessage;      
        if (!insight && !insight.InsightText?.trim()) {
          const noDataMessage = {
              id: Date.now() + 1,
              text: "No insights found for your query. Please try a different question.",
              sender: 'ai',
              timestamp: new Date().toLocaleTimeString(),
              isEmptyInsight: true,
              searchTime: Math.floor((Date.now() - startTime) / 1000)
          };
          setMessages(prev => [...prev, noDataMessage]);
        } else {
          aiMessage = {
              id: Date.now() + 1,
              insightText: insight?.InsightText === "" ?"" : insight?.InsightText,
              sender: 'ai',
              timestamp: new Date().toLocaleTimeString(),
              data: insight?.Chart || [],
              unique_id: uniqueId,
              searchTime: Math.floor((Date.now() - startTime) / 1000)
          };
          setMessages(prev => [...prev, aiMessage]);
        }
        const endTime = Date.now();
        const totalMilliseconds = endTime - startTime;
        const seconds = Math.floor(totalMilliseconds / 1000);
        try {
          const chatLogResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/chatbot/create/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              client_id: window.localStorage.getItem("clientID"),
              email: window.localStorage.getItem("email"),
              question: searchQuery,
              answer:  JSON.stringify(aiMessage),
              created: new Date().toISOString(),
              query_response_time: seconds,
              data_source: dataSource,
              unique_id: uniqueId
            })
          });
    
          if (!chatLogResponse.ok) {
            console.error("Failed to log chat", await chatLogResponse.text());
          }
        } catch (logError) {
          console.error("Error logging chat:", logError);
        }
        setTimeout(() => {
          if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({
              behavior: "smooth",
              block: "end",  
            });
          }
        }, 100)
        setSearching(false);
    } catch (err) {
        const errorMessage = {
            id: Date.now() + 1,
            text: "Failed to fetch data. Please try again.",
            sender: 'ai',
            timestamp: new Date().toLocaleTimeString(),
            isError: true
        };

        setMessages(prev => [...prev, errorMessage]);
    } finally {
        setLoading(false);
        setSearching(false);
    }
  };
  const handleFeedback = async (type, uniqueId, feedback = "") => {
    try {
      const endpoint = type === "positive"
        ? `${process.env.REACT_APP_API_ENDPOINT}/chatbot/feedback/positive/`
        : `${process.env.REACT_APP_API_ENDPOINT}/chatbot/feedback/negative/`;
      const feedbackText = typeof feedback === "string" ? feedback.trim() : "";

        const body = type === "positive"
        ? { unique_id: uniqueId }
        :  feedbackText !== "" ? { unique_id: uniqueId, feedback } : { unique_id: uniqueId,feedback:"" };
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios.put(endpoint, body, { headers });
      if (response.status === 200) {
        if(feedbackText !== ""  ){
          setIsModalOpen(false);
          setFeedback("");
        }
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback");
    }
  };
  
  return (
    <PageWrapper>
      <Header />
      <PageContent>
        {messages?.length !== 0 && <ChatContainer>
          <ChatWindow>
            {messages && messages?.map((message, index) => (
              <>
               
                <MessageBubble key={message.id} sender={message.sender} hasData={!!message.data?.Type} failedToFetch={message.text}>
                {message.sender === 'ai' && message.searchTime && (
                  <SearchTimeIndicator searchTime={message.searchTime} />
                )}
                  <MessageContent sender={message.sender}>
                    <MessageText>{message.text}</MessageText>
                    {message.sender === 'ai' && message.data && !message.isError && (
                      <ResultsContainer>
                        <ResultBody>
                          <DesktopView>
                            <HeaderRow>
                            <PageTitle>{formatTitle(message.data?.Title, message.data)}</PageTitle>
                            </HeaderRow>
                          </DesktopView>

                          <MobileView>
                            <HeaderRow>
                            <PageTitle>{formatTitle(message.data?.Title, message.data)}</PageTitle>
                            </HeaderRow>
                          </MobileView>

                          <ChartContainer>
                            {message?.data?.Type === "Line" ? (
                              <TransformedNivoLineGraph data={message.data} />
                            ) : message?.data?.Type === "Bar" ? (
                              <TransformedNivoBarGraph data={message.data} />
                            ) : message?.data?.Type === "Table" ? (
                              <TransformedTable data={message.data} />
                            ) : null}
                          </ChartContainer>

                          {message.insightText && (
                            <InsightText>
                              <div dangerouslySetInnerHTML={{
                                __html: message.insightText
                                  .replace(/\n/g, '<br/>')
                                  .replace(/- /g, '• ')
                                  .replace(/\*(\d[\d,.BMK]+)\*/g, '<strong>$1</strong>')
                                  .replace(/(INR)/gi, '<strong>$1</strong>')
                              }} />
                            </InsightText>
                          )}
                        </ResultBody>
                      </ResultsContainer>
                    )}

                    <MessageMeta sender={message.sender}>
                      <span></span>
                      {message.sender === 'ai' && !message.isError && (
                        <MessageActions>
                          <TooltipWrapper>
                            <ActionButton
                              onClick={() => {
                                setSelectedFeedback(prev => ({
                                  ...prev,
                                  [message.id]: "positive"
                                }));
                                setOpenFeedbackModalId(message.id);
                                handleFeedback('positive', message.unique_id);
                                setIsModalOpen(false);
                              }}
                              style={{
                                color: selectedFeedback[message.id] === "positive" ? "#0b57d0" : "inherit",
                                outline: "none",
                                border: "none",
                                boxShadow: "none",
                              }}
                            >
                              <FaRegThumbsUp style={{ fontSize: "20px" }} />
                            </ActionButton>
                            <Tooltip>Good Response</Tooltip>
                          </TooltipWrapper>

                          <TooltipWrapper>
                            <ActionButton
                              onClick={() => {
                                setSelectedFeedback(prev => ({
                                  ...prev,
                                  [message.id]: "negative"
                                }));
                                handleFeedback('negative', message.unique_id);
                                setOpenFeedbackModalId(message.id);
                                setIsModalOpen(!isModalOpen);
                              }}
                              style={{
                                color: selectedFeedback[message.id] === "negative" ? "#0b57d0" : "inherit",
                                outline: "none",
                                border: "none",
                                boxShadow: "none",
                              }}
                            >
                              <FaRegThumbsDown style={{ fontSize: "20px" }} />
                            </ActionButton>
                            <Tooltip>Bad Response</Tooltip>
                          </TooltipWrapper>
                        </MessageActions>
                      )}
                    </MessageMeta>
                    {openFeedbackModalId === message.id && isModalOpen && !!message?.data?.Type && (
                      <FeedbackContainer>
                        <FeedbackInputWrapper>
                          <FeedbackInput
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="Tell us more..."
                          />
                          <FeedbackSubmitButton
                            onClick={() => {
                              handleFeedback("negative", message.unique_id, feedback)
                              toast.success('Thank you for Feedback!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                style: {
                                  fontSize: '14px',
                                }
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleFeedback("negative", message.unique_id, feedback)
                                toast.success('Thank you for Feedback!', {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  style: {
                                    fontSize: '14px',
                                  }
                                });
                              }
                            }}
                          >
                            <CiPaperplane />
                          </FeedbackSubmitButton>
                        </FeedbackInputWrapper>
                      </FeedbackContainer>
                    )}
                  </MessageContent>
                </MessageBubble>
              </>
            ))}
            {
              loading && <TypingIndicatorContainer><LoaderProgress /></TypingIndicatorContainer> 
      
            }
            <div ref={chatEndRef} />
          </ChatWindow>
        </ChatContainer>}
        <InvitationsContainer>
          <InvitationsBody>
            <SearchBar hasValue={query} isFocused={isFocused}>
              <Dropdown category={category} setCategory={setCategory} categories={categories} />
              <SearchInput
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={`Search ${category.toLowerCase()} insights...`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onKeyDown={handleKeyDown}
              />
              <SearchButton
                disabled={searching}
                onClick={()=>handleSearch(query)}
                hovered={isHovered}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Ask{" "}
                {isHovered && (
                  <Arrow className="arrow" hovered={isHovered}>
                    <HiArrowNarrowRight size={18} />
                  </Arrow>
                )}
              </SearchButton>
            </SearchBar>
            {messages.length === 0 && (
              <ExampleQuestionsSection>
                <SectionTitle>Questions you can ask:</SectionTitle>
                <QuestionsGrid>
                  {(category === 'Platforms' ? platformQuestions : brandQuestions).map((question, index) => (
                    <QuestionCard
                      key={index}
                      isHovered={hoveredQuestion === index}
                      onMouseEnter={() => {setHoveredQuestion(index);}}
                      onMouseLeave={() => setHoveredQuestion(null)}
                      onClick={() => {
                        setQuery(question);
                        document.querySelector('input[type="text"]')?.focus();
                        setTimeout(() => handleSearch(question), 0); 
                      }}
                    >
                      {question}
                    </QuestionCard>
                  ))}
                </QuestionsGrid>
              </ExampleQuestionsSection>
            )}
          </InvitationsBody>
        </InvitationsContainer>
      </PageContent>
      <Footer />
    </PageWrapper>
  );
};

export default SearchBarComponent;
 const PageWrapper =  styled.div`
 display: flex;
 flex-direction: column;
 height:100vh;
`;
const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eef9ff;
  height: 89vh;
  justify-content: center;
  align-items: center;
  ${media.small`
    height:86vh;
    justify-content: center;
    align-items: center;
  `}
`;

const InvitationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 10px 3.5vw 0px;
  width:50%;
  left: 0;
  // z-index: 1000;
   ${media.small`
    width:100%;
  `}
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  background-color: transparent;
  width: 100%;
  margin-bottom: 10px;
  ${media.small`
    padding: calc(2.2vw - 0px);
    font-size: 14px;
    margin-bottom: -1px;
  `}
`;

const ResultBody = styled.div`
  align-items: center;
  text-align: center;
  filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
  ${media.small`
    padding: 20px;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.08))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12));
  `}
`;

const ChartContainer = styled.div`
  height: 350px; 
  margin-top: 10px;  
  width: 100%;
  overflow: hidden;
  position: relative;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      height: 340px;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior: contain;
    }
  }
`;


const InsightText = styled.div`
  margin-top: 15px;
  font-size: 14px;
  color: #374151;
  text-align: left;
   strong {
    font-weight: 600;
    color: #111827;
  }
  br {
    display: block;
    content: "";
    margin-bottom: 10px;
  }
  ul {
    padding-left: 20px;
    margin: 8px 0;
  }
  li {
    margin-bottom: 6px;
    list-style-type: disc;
  }
  ${media.small`
    font-size: 12px;
  `}
`;

const DesktopView = styled.div`
  ${media.small`
    display: none;
  `}
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const MobileView = styled.div`
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
`;

const PageTitle = styled.div`
  height: 24px; 
  font-size: 18px; 
  font-weight: 500;
  text-align: left;
  color: #020202;
  display: flex;
  position: relative;
  ${media.small`
    font-size: 14px;
  `}
`;
const MessageBubble = styled.div`
  display: flex;
  // gap: 1rem;
  flex-direction:column;
  width: ${({ hasData }) => (hasData && '50%')};
  align-items: flex-start;
  margin-right: ${({ hasData,failedToFetch }) => {
    if (!hasData) {
      return !hasData && failedToFetch === "Failed to fetch data. Please try again."?'625px':!hasData && failedToFetch === "No insights found for your query. Please try a different question."?'450px':"360px"; 
    }
  }};
  padding: 10px 3.5vw 0px;
  align-self: ${({ sender }) => (sender === 'user' ? 'flex-end' : 'center')};
  ${media.small`
    width: ${({ hasData }) => (hasData && '100%')};
    margin-right: ${({ hasData }) => (!hasData && '0px')};
  `}
`;

const MessageContent = styled.div`
  background: ${({ theme }) => theme.sender === 'user' ? '#2563eb' : 'white'};
  color: ${({ theme }) => theme.sender === 'user' ? 'white' : '#333'};
  padding: 1rem 1.2rem;
  width:100%;
  border-radius: ${({ theme }) =>
    theme.sender === 'user' ? '1rem 1rem 0 1rem' : '1rem 1rem 1rem 1rem'};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const MessageText = styled.p`
  margin: 0;
  line-height: 1.5;
`;

const MessageMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  justify-content: center;
  color: ${({ theme }) => theme.sender === 'user' ? 'rgba(255,255,255,0.7)' : '#999'}
`;

const MessageActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 1;
  }
`;

const TypingIndicatorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 400px; 
  margin-top: 10px;
   ${media.small`
    padding-left: 0px; 
    margin-top: 0px;
  `}
`;


const TypingIndicator = styled.div`
  display: flex;
  gap: 0.3rem;
  align-self: flex-start;
  padding: 1rem;
`;

const TypingDot = styled.div`
  width: 8px;
  height: 8px;
  background: #666;
  border-radius: 50%;
  opacity: 0.4;
  animation: pulse 1.4s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
  
  @keyframes pulse {
    0%, 100% { opacity: 0.4; transform: scale(0.8); }
    50% { opacity: 1; transform: scale(1.1); }
  }
`;

const InvitationsBody = styled.div`
  align-items: center;
  text-align: center;
  filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 30px;
  margin-bottom: 7px;
  border: 2px solid #d5d9df;
  padding: 1px 0.5vw 0px;
   &:hover {
    border: ${(props) => ((props.hasValue || props.isFocused) ? "2px solid #3b82f6" : "1px solid #d5d9df")};
  }
  &:focus-within {
    border: ${(props) => ((props.hasValue || props.isFocused) ? "2px solid #3b82f6" : "1px solid #d5d9df")};
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const DropdownButton = styled.button`
  padding: 14px 15px;
  background: #ffffff;
  border: none;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #4b5563;
  width: 102px;
  outline:none;
  &:hover {
    background: #ffffff;
    border:none;
    outline:none;
  }
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 5px;
  width: 110px;
  transform: translate(4%, -167%);
  &:hover {
    background: #ffffff;
    border:none;
    outline:none
  }
`;

const DropdownItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  color: #4b5563;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background: #f3f4f6;
    border-radius: 8px;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px 15px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #4b5563;
  input&[type="text"] {
    border: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
`;

const SearchButton = styled.button`
  padding: 9px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  gap: ${(props) => (props.hovered ? "8px" : "0px")}; 
  width: ${(props) => (props.hovered ? "90px" : "60px")}; 
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &:hover {
    background: #2563eb;
  }
`;

const Arrow = styled.span`
  opacity: ${(props) => (props.hovered ? "1" : "0")}; 
  transform: ${(props) => (props.hovered ? "translateX(0px)" : "translateX(-5px)")};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(92vh - 120px);
  width: 100%;
  position: relative;
`;

const ChatWindow = styled.div`
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
  padding-bottom: 80px;
`;


const Tooltip = styled.div`
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  z-index: 10;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${Tooltip} {
    opacity: 1;
  }
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background: white;
  padding: 16px;
  border-radius: 8px;
  // border: 1px solid #d5d9df;
`;


const FeedbackInputWrapper = styled.div`
 display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 30px;
  margin-bottom: 7px;
  border: 1px solid #d5d9df;
  padding: 1px 0.5vw 0px;
  &:focus-within {
    border: 2px solid #3b82f6;
  }
`;

const FeedbackInput = styled.input`
  flex: 1;
  padding: 3px 15px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #4b5563;
  input&[type="text"] {
    border: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
`;

const FeedbackSubmitButton = styled.div`
  padding: 8px 16px;
  margin: 4px;
  color: #4b5563;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  &:hover {
    color: #4b5563;
  }
  
  &:disabled {
    color: #93c5fd;
    cursor: not-allowed;
  }
`;

const ExampleQuestionsSection = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const SectionTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 12px;
  text-align: left;
  margin-left:19px;
`;

const QuestionsGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // gap: 12px;
`;

const QuestionCard = styled.div`
  padding: 12px 16px;
  background: ${props => props.isHovered ? '#f3f4f6' : '#ffffff'};
  border: 0.1px solid ${props => props.isHovered ? '#d1d5db' : '#e5e7eb'};
  border-radius: 30px;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  color: #111827;
  box-shadow: ${props => props.isHovered ? '0 1px 3px rgba(0,0,0,0.1)' : 'none'};
  opacity: ${props => props.isHovered ? 0.6 : 0.9};
  &:hover {
    background: #f3f4f6;
    border-color: #d1d5db;
    transform: translateY(-1px);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  &:active {
    transform: translateY(0);
  }
`;
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px;
`;

const LoaderAnimation = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const LoaderDot = styled.div`
  width: 12px;
  height: 12px;
  background: #2563eb;
  border-radius: 50%;
  opacity: 0.7;
  animation: bounce 1.4s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
  
  @keyframes bounce {
    0%, 100% { 
      transform: translateY(0);
      opacity: 0.7;
    }
    50% { 
      transform: translateY(-10px);
      opacity: 1;
    }
  }
`;

const LoaderText = styled.div`
  font-size: 14px;
  color: #4b5563;
  text-align: center;
`;
const SearchTimeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  // margin: 8px 0;
  // padding-left: 430px;
  // ${media.small`
  //   padding-left: 0px; 
  // `}
`;

const SearchTimeText = styled.span`
  font-size: 12px;
  color: white;
  background: #2563eb;
  padding: 4px 12px;
  border-radius: 12px;
`;
const WaveLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const WaveLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 40px;
`;

const WaveBar = styled.div`
  width: 6px;
  height: 20px;
  background: linear-gradient(to top, #2563eb, #3b82f6);
  border-radius: 3px;
  animation: wave 1.5s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
  
  @keyframes wave {
    0%, 100% { 
      height: 10px;
    }
    50% { 
      height: 30px;
    }
  }
`;
