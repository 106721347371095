import { ResponsiveLine } from "@nivo/line";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const myColors = [
    "#0099FF",
    "#A962FF",
    "#26CDCD",
    "#9FC51E",
    "#FF832B",
    "#FCBB53",
    "#21AF4A",
    "#66C2FF",
    "#4673FF",
    "#F28D8C",
    "#7D7DE8",
    "#AFEEEE",
    "#DA70D6",
    "#98FB98",
    "#F58CBD",
    "#FFDD44",
    "#89CFF0",
    "#FF6F61",
    "#B1EDE8",
    "#C9A0DC",
    "#93DFB8",
    "#FFD700",
    "#F4A460",
    "#ADD8E6",
    "#FFB347",
    "#77DD77",
    "#DDA0DD",
    "#FFC0CB",
    "#FF6961",
    "#B39EB5",
    "#FFB6C1",
  ];
  
  const blurNumber = (value, blurChar = "█") => {
    if (value === null || value === undefined) return value;
    const valueStr = String(value);
    const blurredValue = blurChar.repeat(valueStr.length);
    return blurredValue;
 };
const TransformedNivoLineGraph = ({ data }) => {
    const [transformedData1, setTransformedData1] = useState([]);
    const [transformedData2, setTransformedData2] = useState([]);
    const [isScale, setScale] = useState(false);
    const [isCurrency, setCurrency] = useState(false);
    const [title, setTitle] = useState(data?.Title || "");
    const [isUSDPresent, setIsUSDPresent] = useState(false);
    const [usdToINRRate, setUsdToINRRate] = useState(0);
    const allDates = data?.Labels?.Data || [];
    const legendPadding = 50;
    const fontSize = 9;
    const tickRotation = 0;
    const marginTop = 30;
    const marginRight = 30;
    const marginBottom = 40;
    const marginLeft = 40;
    const is_platform = false;
    const bigSize = 15;
    const smallSize = 5;
    const formatCode = "hi";
    const frequency = "M";
    const is_percent = false;
    const thousandScaling = false;
    const isBlurred = false;
    const legendDirection = "row";
    const topOption = [];
    const paddingRatio = 0.5;
    const showTotal = false;
    const is_usd = false;
    const isINR = data?.Title?.includes("INR");
    const isHashTitle = data?.Title?.includes("#");
  useEffect(() => {
    if (!data?.Title) return;
    const usdMatch = data.Title.match(/USD=([\d.]+)\s*INR/);
    if (usdMatch) {
      setIsUSDPresent(true);
      setUsdToINRRate(parseFloat(usdMatch[1]));
      const maxValue = Math.max(...data.Values.flatMap((v) => v.Data));
      if (maxValue >= 1_000_000) {
        setTitle(data.Title.replace(/USD=[\d.]+ INR/, `USD=${usdMatch[1]} INR (USD Mn)`));
        setScale(true);
      } else {
        setTitle(data.Title);
      }
    } else {
      setTitle(data.Title);
    }
  }, [data]);

    const maxValue = Math.max(...data.Values.flatMap((v) => v.Data));
   const isMnScale = (isHashTitle && maxValue >= 1_000_000) ||
    (isUSDPresent && maxValue >= 1_000_000);
  const isCrScale = isINR && maxValue >= 10_000_000 && !isUSDPresent;
  const yAxisUnit = isMnScale ? (isUSDPresent ? "USD Mn" : "Mn") :
    isCrScale ? "INR Cr" :
      data?.Values?.[0]?.Unit || "Y-Axis";

    const currency_unit = isCurrency
      ? isScale
        ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Bn" : "Mn"})`
        : ` (${is_usd ? "USD" : "INR"})`
      : isScale
        ? ` (${is_usd ? "Mn" : "Cr"})`
        : ``;
    useEffect(() => {
      if (!data || !data.Values || !data.Labels || !data.Labels.Data) {
        return;
      }
  
      try {
        const nivoData = data.Values.map((series) => ({
          id: series.Legend,
          data: series.Data.map((value, index) => ({
            x: data.Labels.Data[index],
            y: value,
          })),
        }));
  
        let dataList = data.Values.map((item) => ({
          id: item.Legend,
          data: allDates.map((date, index) => ({
            x: date,
            y: item.Data[index] || null,
          })),
        }));
  
        setTransformedData1(nivoData);
        setTransformedData2(dataList);
      } catch (error) {
        console.error("Error transforming data:", error);
      }
    }, [data, allDates]);

    const CustomTooltip = ({ slice }) => {
      if (!slice || !slice.points) return null;

      const reversedData = [...slice.points].reverse();
      const topData = reversedData.filter(
        (obj) =>
          (topOption.length === 0 || topOption.includes(obj.serieId)) &&
          obj.serieId !== "Overall"
      );
      const totalValue = topData.reduce(
        (sum, dataPoint) => sum + dataPoint.data.y,
        0
      );
      const totalValueList = reversedData.filter(
        (obj) => obj.serieId === "Overall"
      );
    
      const otherData = reversedData.filter(
        (obj) => !topData.includes(obj) && obj.serieId !== "Overall"
      );
    
      const formatTooltipValue = (value) => {
        let scaledValue, unit;
        
        if (isMnScale) {
          scaledValue = value / 1_000_000;
          unit = isUSDPresent ? "(Mn)" : "(Mn)";
        } else if (isCrScale) {
          scaledValue = value / 10_000_000;
          unit = "(INR Cr)";
        } else {
          scaledValue = value;
          unit = is_percent ? "%" : currency_unit;
        }
        
        const formattedValue = scaledValue.toLocaleString(formatCode, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        
        return { formattedValue, unit };
      };
      
    
      return (
        <TootTipWrapper fontSize={fontSize}>
          {showTotal && totalValueList.length > 0 && (
            <TootTipRow>
              <TootTipLabel bold={true}>
                <SquareBox size={bigSize} color={"#cccccc"} bold={true} />
                Overall
              </TootTipLabel>
              <TootTipValue bold={true}>
                {isBlurred
                  ? blurNumber(formatTooltipValue(totalValueList[0].data.y).formattedValue)
                  : formatTooltipValue(totalValueList[0].data.y).formattedValue}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)} bold={true}>
                  {formatTooltipValue(totalValueList[0].data.y).unit}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipRow>
          )}
          {topData.map((point, idx) => {
            const formatted = formatTooltipValue(point.data.y);
            return (
              <TootTipRow key={idx}>
                <TootTipLabel>
                  <SquareBox size={bigSize} color={point.serieColor} />
                  {point.serieId}
                </TootTipLabel>
                <TootTipValue>
                  {isBlurred
                    ? blurNumber(formatted.formattedValue)
                    : formatted.formattedValue}
                  <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                    {formatted.unit}
                  </TootTipValueUnit>
                </TootTipValue>
              </TootTipRow>
            );
          })}
          {otherData.map((point, idx) => {
            const formatted = formatTooltipValue(point.data.y);
            return (
              <TootTipOtherRow key={idx} margin={bigSize}>
                <TootTipLabel>- {point.serieId}</TootTipLabel>
                <TootTipValue>
                  {isBlurred
                    ? blurNumber(formatted.formattedValue)
                    : formatted.formattedValue}
                  <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                    {formatted.unit}
                  </TootTipValueUnit>
                </TootTipValue>
              </TootTipOtherRow>
            );
          })}
        </TootTipWrapper>
      );
    };
    const LabelLayer = ({ series }) => {
      if (!series || series.length === 0) return null;

      let maxY = -10000;
      let decimalCount = 2;
      let cordinates = {};

      series.forEach(({ id, data, color }) => {
        if (!data) return;

        data.forEach(({ data, position }) => {
          if (!position) return;

          if (position.y != null) {
            let newArry = [];
            if (cordinates[position.x]) {
              newArry = cordinates[position.x];
            }
            newArry.push(position.y);
            cordinates[position.x] = newArry.sort((a, b) => b - a);
          }
          if (data && data.y > maxY) {
            maxY = data.y;
          }
        });
      });
  
      if (maxY > 100) {
        decimalCount = 1;
      }
      if (maxY > 1000) {
        decimalCount = 0;
      }
  
      let validCordinated = {};
      Object.keys(cordinates).forEach((x) => {
        let yCords = [];
        cordinates[x].forEach((y) => {
          if (yCords.length === 0 || yCords[yCords.length - 1] - y >= fontSize) {
            yCords.push(y);
          }
        });
        validCordinated[x] = yCords;
      });
  
      return (
        <g>
          {series.map(({ id, data, color }) => {
            if (!data) return null;
  
            return data.map(({ data, position }) => {
              if (!position || !data) return null;
  
              let legendVisible = false;
              if (
                validCordinated[position.x]?.filter(
                  (yCord) => yCord === position.y
                ).length
              ) {
                validCordinated[position.x] = validCordinated[position.x].filter(
                  (yCord) => yCord !== position.y
                );
                legendVisible = true;
              }
              const scaleThousand = thousandScaling && data.y > 1000;
              const pointDecimal = scaleThousand
                ? data.y > 10000
                  ? 0
                  : 1
                : decimalCount;
              const formattedValue = legendVisible
                ? `${Number(
                  (isMnScale ? data.y / 1_000_000 :
                    isCrScale ? data.y / 10_000_000 :
                      scaleThousand ? data.y / 1000 : data.y).toFixed(pointDecimal)
                ).toLocaleString(formatCode, {
                  minimumFractionDigits: pointDecimal,
                  maximumFractionDigits: pointDecimal,
                })}${isMnScale ? "" : isCrScale ? "" : scaleThousand ? "K" : ""}${is_percent ? "%" : ""}`
                : null;
              const displayValue =
                legendVisible && isBlurred
                  ? blurNumber(formattedValue)
                  : formattedValue;
              return (
                <text
                  key={`${id}-${position.x}`}
                  x={position.x + 14}
                  y={position.y - 10}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fill: "#000000",
                    fontSize: fontSize,
                    fontWeight: "normal",
                    // filter: isBlurred ? "blur(10px)" : "none",
                  }}
                >
                  {displayValue}
                </text>
              );
            });
          })}
        </g>
      );
    };
  
    const CustomLegendLayer = ({ series }) => {
      if (!series || series.length === 0) return null;
  
      const isRow = legendDirection === "row";
      let xPosition = legendPadding - marginLeft;
      let yPosition = -marginTop + bigSize / 2;
  
      return (
        <g>
          <text
            x={xPosition}
            y={yPosition}
            textAnchor="end"
            alignmentBaseline="middle"
            fill="#262e40"
            fontSize={fontSize + 2}
            fontWeight="bold"
          >
            Platforms
          </text>
          {
            series
              .map((item, index) => {
                const xCurrentPosition = xPosition;
                xPosition = (item.id.length * fontSize) / 2 + xPosition + 30;
                const yCurrentPosition = yPosition;
                yPosition = yPosition + 12;
                return (
                  <g
                    key={item.id}
                    transform={
                      isRow
                        ? `translate(${xCurrentPosition},${-marginTop + bigSize / 2
                        })`
                        : `translate(${legendPadding - marginLeft
                        },${yCurrentPosition})`
                    }
                  >
                    <circle cx={10} cy={0} r={bigSize / 2} fill={item.color} />
                    <text
                      x={20}
                      y={0}
                      textAnchor="start"
                      alignmentBaseline="middle"
                      fill="#262e40"
                      fontSize={fontSize}
                    >
                      {item.id}
                    </text>
                  </g>
                );
              })}
        </g>
      );
    };
    if (!transformedData1 || transformedData1.length === 0) {
      return <div>Loading graph data...</div>;
    }
   
  
    return (
      <Wrapper>
        <GraphWrapper>
          <ResponsiveLine
            data={transformedData1}
            colors={
              is_platform
                ? (series) => {
                  if (!myColors || !myColors[series.id]) {
                    console.warn(`Color mapping missing for series: ${series.id}`);
                    return "#FAEBD7";
                  }
                  return myColors[series.id];
                }
                : myColors
            }
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: "#333333",
                    strokeWidth: 1.2,
                  },
                },
              },
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat={(value) => {
              let scaledValue;
              if (isMnScale) {
                scaledValue = value / 1_000_000;
              } else if (isCrScale) {
                scaledValue = value / 10_000_000;
              } else {
                scaledValue = value;
              }
              
              const formatted = `${Number(scaledValue.toFixed(2)).toLocaleString(
                formatCode,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`;
              return isBlurred ? blurNumber(formatted) : formatted;
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 12,
              tickRotation: 0,
              legendOffset: 40,
              legendPosition: 'middle',
              renderTick: ({ value, x, y }) => (
                <text
                  x={x - 4}
                  y={y + 20}
                  textAnchor="middle"
                  style={{
                    fill: '#666',
                    fontSize: 11,
                    fontFamily: 'inherit',
                  }}
                >
                  {value}
                </text>
              )
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 12,
              tickRotation: 0,
              tickValues: 5,
              legend: yAxisUnit,
              legendOffset: -52,
              legendPosition: "middle",
              format: (value) => {
                let displayValue;
                if (isMnScale) {
                  displayValue = value / 1_000_000;
                } else if (isCrScale) {
                  displayValue = value / 10_000_000;
                } else {
                  displayValue = value;
                }
                return displayValue;
              },
              renderTick: ({ value, x, y }) => {
                let displayValue;
                if (isMnScale) {
                  displayValue = value / 1_000_000;
                } else if (isCrScale) {
                  displayValue = value / 10_000_000;
                } else {
                  displayValue = value;
                }
                return (
                  <text
                    x={x - 5}
                    y={y}
                    textAnchor="end"
                    style={{
                      fill: '#666',
                      fontSize: 11,
                      fontFamily: 'inherit',
                    }}
                  >
                    {displayValue.toLocaleString()}
                  </text>
                );
              }
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={smallSize}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "color", modifiers: [] }}
            enablePointLabel={false}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            areaOpacity={0}
            enableSlices="x"
            sliceTooltip={CustomTooltip}
            enableCrosshair={true}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[]}
            motionConfig="default"
            isInteractive={true}
            layers={[
              "grid",
              "markers",
              "areas",
              "lines",
              "slices",
              "points",
              "axes",
              "legends",
              "crosshair",
              LabelLayer,
              CustomLegendLayer,
            ]}
          />
        </GraphWrapper>
        <GraphWrapper2>
          <ResponsiveLine
            data={transformedData2}
            colors={
              is_platform
                ? (series) => {
                  if (!myColors || !myColors[series.id]) {
                    console.warn(`Color mapping missing for series: ${series.id}`);
                    return "#FAEBD7";
                  }
                  return myColors[series.id];
                }
                : myColors
            }
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            theme={{
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat={(value) => {
              const formatted = `${Number(value.toFixed(2)).toLocaleString(
                formatCode,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`;
              return isBlurred ? blurNumber(formatted) : formatted;
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={false}
            enableGridX={false}
            enableGridY={false}
            lineWidth={0}
            enablePoints={false}
            pointSize={smallSize}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "color", modifiers: [] }}
            enablePointLabel={false}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            areaOpacity={0}
            enableSlices="x"
            sliceTooltip={CustomTooltip}
            enableCrosshair={true}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[]}
            motionConfig="default"
          />
        </GraphWrapper2>
      </Wrapper>
    );
};

export default TransformedNivoLineGraph;

const TootTipWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 8px;
    border: 0.5px solid #cccccc;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    font-size: ${(props) => props.fontSize}px;
 `;
  
  const TootTipRow = styled.div`
    width: 100%;
    display: flex;
    padding: 2.5px;
    flex-direction: row;
    justify-content: space-between;
  `;
  
  const TootTipOtherRow = styled.div`
    width: 100%;
    display: flex;
    padding: 2.5px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${(props) => 12.5 + props.margin}px;
  `;
  
  const TootTipLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    font-weight: ${(props) => (props.bold ? "500" : "400")};
  `;
  
  const SquareBox = styled.div`
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    background-color: ${(props) => props.color};
    margin-right: 10px;
    border: ${(props) =>
      props.bold ? "1.5px solid #262e40" : "0px solid #262e40"};
  `;
  
  const TootTipValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: ${(props) => (props.bold ? "500" : "400")};
  `;
  
  const TootTipValueUnit = styled.div`
    font-size: ${(props) => props.fontSize}px;
    margin-left: 3px;
    color: #a0a6a9;
    font-weight: 350;
    font-weight: ${(props) => (props.bold ? "500" : "350")};
  `;
  
  const GraphWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `;
  
  const GraphWrapper2 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: all;
  `;
  
  const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
  `;